import { images } from 'config/images'
import { EXTERNAL_LINK } from 'config/utils'

export const navProps = [
  {
    navLink: '/services/gen-ai-ml-development',
    navTitle: 'Gen AI and ML',
    type: EXTERNAL_LINK,
    navSubmenu: [
      {
        navLink: '/services/artificial-intelligence-development/',
        navTitle: 'AI Development',
        info: 'Use ML tools and algorithms to build intelligent AI-driven apps',
        menuIcon: images.aiMicon,
      },
      {
        navLink: '/services/generative-ai-development',
        navTitle: 'Generative AI',
        info: 'Build intelligent generative AI solutions that adapt and evolve',
        menuIcon: images.gaiMicon,
        type: EXTERNAL_LINK,
      },
      {
        navLink: '/services/machine-learning-development',
        navTitle: 'ML Development',
        info: 'Build and deploy custom ML models to automate tasks',
        menuIcon: images.mlMicon,
        type: EXTERNAL_LINK,
      },
      {
        navLink: '/services/mlops',
        navTitle: 'MLOps',
        info:
          'Build sophisticated ML and CI/CD pipelines and shorten production cycles',
        menuIcon: images.mlopsMicon,
        type: EXTERNAL_LINK,
      },
      {
        navLink: '/services/llm-development',
        navTitle: 'LLM Development',
        info: 'Accelerate LLM adoption and streamline its implementation',
        menuIcon: images.llmMicon,
        type: EXTERNAL_LINK,
      },
      {
        navLink: '/services/data-science-consulting',
        navTitle: 'Data Science Consulting',
        info:
          'Get expert guidance to leverage data for operational improvements',
        menuIcon: images.consultingMicon,
        type: EXTERNAL_LINK,
      },
    ],
  },
  {
    navLink: '/amazon-web-services/',
    navTitle: 'AWS',
    navSubmenu: [
      {
        navLink: '/services/aws-generative-ai/',
        navTitle: 'Gen AI and ML',
        info: 'Build domain-specific generative AI solutions on AWS',
        menuIcon: images.alMlDevMicon,
        type: EXTERNAL_LINK,
      },
      {
        navLink: '/services/aws-cloud-migration/',
        info: 'Move your current workloads to AWS Cloud environment',
        navTitle: 'Migration and Modernization',
        menuIcon: images.cloudMigrationMicon,
        type: EXTERNAL_LINK,
      },
      {
        navLink: '/services/aws-saas-development/',
        navTitle: 'SaaS Migration and Engineering',
        info:
          'Build, migrate, and optimize SaaS applications through cloud-native solution',
        menuIcon: images.awsSaasIcon,
        type: EXTERNAL_LINK,
      },
      {
        navLink: '/services/aws-data-engineering/',
        navTitle: 'Data Science and Engineering',
        info:
          'Build and optimize your data processing pipelines to improve operational efficiency',
        menuIcon: images.biDataEngineeringMicon,
      },
      {
        navLink: '/services/aws-serverless-and-orchestration/',
        navTitle: 'Serverless',
        info:
          'Manage complex workflows and ensure optimal resource utilization',
        menuIcon: images.serverlessMicon,
      },
      {
        navLink: '/services/aws-cloud-management/',
        navTitle: 'Cloud Management',
        info:
          'Improve AWS efficiency, automation, and visibility for better cloud operations',
        menuIcon: images.cloudArchDesignMicon,
      },
    ],
  },
  {
    navLink: '/services/digital-product-engineering/',
    navTitle: 'Product Engineering',
    navSubmenu: [
      {
        navLink: '/services/software-product-development/',
        navTitle: 'Product Engineering and Development',
        info:
          'Build products powered by latest tech stacks and design thinking',
        menuIcon: images.digitalProductEngMicon,
      },
      {
        navLink: '/services/custom-software-development/',
        navTitle: 'Custom Software Development',
        info:
          'Build scalable, robust software designed to meet your business needs',
        menuIcon: images.customSoftwareIcon,
      },
      {
        navLink: '/services/performance-engineering/',
        navTitle: 'Performance Engineering and Testing',
        info:
          'Build products that perform optimally in normal and extreme load conditions',
        menuIcon: images.performanceTestingMicon,
        type: EXTERNAL_LINK,
      },
      {
        navLink: '/services/software-testing/',
        navTitle: 'Quality Engineering',
        info: 'Ensure product quality and customer satisfaction',
        menuIcon: images.testAutomationMicon,
      },
      {
        navLink: '/services/project-strategy/',
        navTitle: 'Project Strategy',
        info: 'Build an agile, adaptive, and transformative project strategy',
        menuIcon: images.projectStrategyMicon,
        type: EXTERNAL_LINK,
      },
      {
        navLink: '/services/digital-experience-design/',
        navTitle: 'Digital Experience Design',
        info:
          'Create digital experiences that engage users at every touch point',
        menuIcon: images.digitalCustomerExperienceIcon,
        type: EXTERNAL_LINK,
      },
    ],
  },
  {
    navLink: '#',
    navTitle: 'Industries',
    navSubmenu: [
      {
        navLink: '/industry/finance/',
        navTitle: 'Financial Services',
        info: 'Build secure, scalable, precision-engineered BFSI solutions',
        menuIcon: images.iconCost,
        type: EXTERNAL_LINK,
      },
      {
        navLink: '/industry/retail/',
        navTitle: 'Retail and E-commerce',
        info:
          'Ensure a consistent customer experience and operational efficiency',
        menuIcon: images.iconEcommerceWeb,
        type: EXTERNAL_LINK,
      },
      {
        navLink: '/industry/healthcare/',
        navTitle: 'Healthcare and Life Sciences',
        info:
          'Build secure, compliant solutions for better patient care and improved efficiency',
        menuIcon: images.iconHealthBenefits,
        type: EXTERNAL_LINK,
      },
      {
        navLink: '/industry/supply-chain/',
        navTitle: 'Supply Chain & Logistics',
        info:
          'Bring agility, resilience, and intelligence to your supply chain',
        menuIcon: images.carrierDispatchIcon,
        type: EXTERNAL_LINK,
      },
      {
        navLink: '/industry/martech/',
        navTitle: 'Marketing and Technology',
        info:
          'Transform marketing efforts and optimize campaigns through intelligent automation',
        menuIcon: images.smarterItOutsourcingMicon,
        type: EXTERNAL_LINK,
      },
      {
        navLink: '/industry/manufacturing/',
        navTitle: 'Manufacturing',
        info:
          'Adopt modern solutions to automate workflow and improve product quality',
        menuIcon: images.menuManufacturingIcon,
        type: EXTERNAL_LINK,
      },
    ],
  },
  {
    navLink: '/case-studies/',
    navTitle: 'Case Studies',
    type: EXTERNAL_LINK,
  },
  {
    navLink: '#',
    navTitle: 'Resources',
    navSubmenu: [
      {
        navLink: '/category/ai-ml-development/',
        navTitle: 'AI & ML Insights',
        menuIcon: images.menuAiMlInsightsIcon,
        info:
          'Gain insights on the latest stories, reports, surveys, and updates on AI/ML',
        type: EXTERNAL_LINK,
      },
      {
        navLink: '/category/product-engineering/',
        navTitle: 'Product Engineering Insights',
        menuIcon: images.menuProductEngInsightsIcon,
        info:
          'Get a deeper understanding of product development with our expert insights',
        type: EXTERNAL_LINK,
      },
      {
        navLink: '/category/cloud/',
        navTitle: 'Cloud Engineering Insights',
        menuIcon: images.menuCloudEngInsightsIcon,
        info:
          'Stay updated with the latest trends and best practices in cloud engineering',
        type: EXTERNAL_LINK,
      },
      {
        navLink: '/blog/',
        navTitle: 'Blog',
        info:
          'A collection of insights on latest technology, best practices and proven strategies',
        menuIcon: images.blogMicon,
        type: EXTERNAL_LINK,
      },
      {
        navLink: '/ebooks/',
        navTitle: 'Ebooks',
        info:
          'Download ebooks from our experts to know the winning strategies, technologies, and trends',
        menuIcon: images.ebooksMicon,
        type: EXTERNAL_LINK,
      },
      {
        navLink: '/blog/#news&insights',
        navTitle: 'News and Tech Insights',
        menuIcon: images.newsMicon,
        info: 'Keep up with the latest technology news and insights',
        type: EXTERNAL_LINK,
      },
    ],
  },
  {
    navLink: '/about-us/',
    navTitle: 'About Us',
  },
]
