import React, { Component } from 'react'
import { Link } from 'gatsby'
import { NavStyle } from './Nav.styles'
import { EXTERNAL_LINK, isWindowDefined } from 'config/utils'
import { navProps } from 'components/Props/menu-props'

class Nav extends Component {
  showMenu = () => {
    return navProps.map((navProp, i) => {
      return (
        <li key={i}>
          {navProp.navSubmenu && (
            <div className="submenu">
              <div className="header-container">
                <ul>
                  {navProp.navSubmenu.map((submenuProps, i) => {
                    return (
                      <li key={`${i}${i}`}>
                        {submenuProps.type !== EXTERNAL_LINK ? (
                          <Link
                            activeClassName="active"
                            to={submenuProps.navLink}
                            className={submenuProps.className}
                          >
                            <div className="icon">
                              <img src={submenuProps.menuIcon} alt="" />
                            </div>
                            <div className="title">
                              <h3>{submenuProps.navTitle}</h3>
                              <p>{submenuProps.info}</p>
                            </div>
                          </Link>
                        ) : (
                          <a
                            href={`${
                              isWindowDefined ? window.location.origin : ''
                            }${submenuProps.navLink}`}
                            className={submenuProps.className}
                          >
                            <div className="icon">
                              <img src={submenuProps.menuIcon} alt="" />
                            </div>
                            <div className="title">
                              <h3>{submenuProps.navTitle}</h3>
                              <p>{submenuProps.info}</p>
                            </div>
                          </a>
                        )}
                      </li>
                    )
                  })}
                </ul>
              </div>
            </div>
          )}
          {navProp.type !== EXTERNAL_LINK ? (
            <Link activeClassName="active" to={navProp.navLink}>
              {navProp.navTitle}
            </Link>
          ) : (
            <a
              href={`${isWindowDefined ? window.location.origin : ''}${
                navProp.navLink
              }`}
            >
              {navProp.navTitle}
            </a>
          )}
        </li>
      )
    })
  }
  render() {
    return (
      <NavStyle className="navigation-menu">
        <ul>{this.showMenu()}</ul>
      </NavStyle>
    )
  }
}

export default Nav
