import React, { useEffect } from 'react'
import 'slick-carousel/slick/slick.css'
import { ClientLogoStyle } from './ClientsLogo.styles'
import { infiniteslide } from 'components/slider-main'
import { useLazyLoad } from 'components/hooks/useLazyload'

const ClientsLogo = ({ clientlogodata }) => {
  const selector = 'client-logo-listing'
  const { clientLogoPrpos } = clientlogodata

  const { showContent } = useLazyLoad('.buildapp-section')

  useEffect(() => {
    let elem = document.querySelector(`.${selector}`)
    let options = {
      root: document.querySelector('.client-logo-section'),
      rootMargin: '0px',
      threshold: [0],
    }

    // intersection observer code
    import('intersection-observer').then(_ => {
      if (elem) {
        let observer = new IntersectionObserver((entries, observer) => {
          let entry = entries[0]
          if (entry.intersectionRatio >= 0.2) {
            infiniteslide(`.${selector}`, { speed: 40 })
            observer.unobserve(elem)
          }
        }, options)
        observer.observe(elem)
      }
    })
  }, [showContent])

  return (
    <ClientLogoStyle className="client-logo-section">
      <div className="client-heading">
        <h4>{clientLogoPrpos.clientLogoHeading}</h4>
      </div>
      {showContent && (
        <div className="client-logo-listing-wrap">
          <div className={selector}>
            {clientLogoPrpos.clientLogos.map((clientLogo, i) => {
              return (
                <div key={i} className="logo-wrap">
                  <a
                    href={clientLogo.clientLogoLink}
                    rel="nofollow noopener noreferrer"
                    target="_blank"
                    className="logo-block"
                  >
                    <img
                      src={clientLogo.clientLogosrc}
                      alt={clientLogo.clientLogoAlt}
                    />
                    <img
                      className="hvr-logo"
                      src={clientLogo.clientLogosrcg}
                      alt={clientLogo.clientLogoAlt}
                    />
                  </a>
                </div>
              )
            })}
          </div>
          <div className="client-count">
            <h5>{clientLogoPrpos.clientCount}</h5>
            <span>{clientLogoPrpos.clienttitle}</span>
          </div>
        </div>
      )}
    </ClientLogoStyle>
  )
}

export default ClientsLogo
