import styled from 'styled-components'
import {
  lg,
  headingFont,
  themeColor,
  bodyFont,
  primaryColor,
  sh,
  white,
  xl,
} from 'config/variables'

export const NavStyle = styled.nav`
  ${lg(`
    display: none;
    `)}
  > ul {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
    padding: 0 20px;
    ${xl(`
    justify-content: space-between;
    gap:0px;
  `)}
    > li {
      list-style: none;
      font-family: ${headingFont};
      line-height: 18px;
      font-weight: 500;
      letter-spacing: 0.007em;
      font-size: 16px;
      display: flex;
      align-items: center;
      ${xl(`
        font-size: 15px;
      `)}
      > a {
        transition: all 0.3s cubic-bezier(0.29, 0.19, 0.405, 1);
        color: rgba(61, 61, 61, 0.65);
        padding: 10px 0 10px;
        display: block;
        position: relative;
        ${sh(`
          padding: 8px 0 8px;
        `)}
        &:before {
          content: '';
          width: 16px;
          height: 16px;
          transform: translateY(10px) rotate(45deg);
          position: absolute;
          background: ${white};
          left: 50%;
          margin-left: -8px;
          top: 51px;
          z-index: 1;
          border-top-left-radius: 2px;
          opacity: 0;
          visibility: hidden;
          transition: all 0.3s cubic-bezier(0.29, 0.19, 0.405, 1);
          pointer-events: none;
        }
        &:after {
          transition: all 0.3s cubic-bezier(0.29, 0.19, 0.405, 1);
          transform: scaleX(0);
          position: absolute;
          bottom: 0;
          left: 0;
          transform-origin: 50%;
          background: ${themeColor};
          width: 100%;
          height: 2px;
          content: '';
        }
        &.active {
          color: ${themeColor};
          &:after {
            transform: scaleX(1);
          }
        }
      }
      &:hover {
        a {
          color: ${themeColor};
          &:after {
            transform: scaleX(1);
          }
        }
        .submenu {
          transform: translate(-50%, 0px);
          opacity: 1;
          visibility: visible;
          pointer-events: auto;
        }
        .submenu + a {
          &:before {
            opacity: 1;
            visibility: visible;
            transform: translateY(0) rotate(45deg);
          }
        }
      }
      .submenu {
        position: absolute;
        width: 100%;
        left: 50%;
        transform: translate(-50%, 10px);
        top: 45px;
        background: ${white};
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.08);
        opacity: 0;
        visibility: hidden;
        pointer-events: none;
        border-radius: 5px;
        transition: all 0.3s ease;
        margin-top: 15px;
        &:after {
          content: '';
          width: 100%;
          height: 25px;
          position: absolute;
          background: transparent;
          left: 0;
          top: -25px;
        }
        ul {
          display: grid;
          grid-template-columns: 1fr 1fr 1fr;
          gap: 10px 20px;
          z-index: 1;
          position: relative;
          background: ${white};
          padding: 20px 0;
          li {
            list-style: none;
            a {
              display: flex;
              align-items: center;
              gap: 16px;
              padding: 16px;
              height: 100%;
              transition: all 0.3s cubic-bezier(0.29, 0.19, 0.405, 1);
              border-radius: 3px;
              .icon {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 50px;
                height: 50px;
                flex: 0 0 50px;
                border-radius: 50%;
              }
              .title {
                h3 {
                  font-size: 14px;
                  line-height: 16px;
                  letter-spacing: -0.11px;
                  color: ${primaryColor};
                  font-family: ${headingFont};
                  font-weight: 700;
                  text-transform: initial;
                  margin: 0 0 2px;
                  transition: all 0.3s cubic-bezier(0.29, 0.19, 0.405, 1);
                }
                p {
                  font-size: 12px;
                  line-height: 16px;
                  font-weight: 400;
                  color: rgba(61, 61, 61, 0.65);
                  margin: 0px;
                  display: block;
                }
              }
            }
            &:hover {
              a {
                background-color: rgb(255 220 226 / 25%);
                .title h3 {
                  color: ${themeColor};
                }
              }
            }
          }
        }
      }
    }
  }
`
